<template>
    <div>
        <el-steps :active="activeMore" finish-status="success" style="padding: 0 10%">
            <el-step title="上传文件"></el-step>
            <el-step title="执行导入"></el-step>
            <el-step title="导入完成"></el-step>
        </el-steps>
        <div v-show="activeMore === 0" style="padding: 0 10%">
            <first-step-more
                ref="importMoreRef"
                :importMoreForm="importMoreForm"
                :importMoreInfo="importMoreInfo" 
            >
                <slot></slot>
            </first-step-more>
        </div>
        <div v-show="activeMore === 1">
            <second-step-more
                :show="show"
                :importMoreForm="importMoreForm"
                :uploadExcelHeaderMore="uploadExcelHeaderMore"
                :systemExcelHeaderMore="systemExcelHeaderMore"
                :keyMapMore="keyMapMore"
                :importMoreInfo="importMoreInfo"
            />
        </div>
        <div v-show="activeMore === 2">
            <third-step-more :successDataMore="successDataMore" />
        </div>
    </div>
</template>
<script>
import FirstStepMore from './FirstStepMore.vue';
import secondStepMore from "./secondStepMore";
import thirdStepMore from './thirdStepMore.vue'
export default {
    name: "ExamImportMore",
    components: {
        FirstStepMore,
        secondStepMore,
        thirdStepMore
    },
    props: {
        activeMore: {
            type: Number,
        },
        importMoreForm: {
            type: Object,
        },
        importMoreInfo: {
            type: Object,
        },
        uploadExcelHeaderMore: {
            type: Array,
        },
        systemExcelHeaderMore: {
            type: Array,
        },
        keyMapMore: {
            type: Object,
        },
        show: {
            type: Boolean,
            default: true
        },
        successDataMore: {
            type: Number
        }
    },
}
</script>