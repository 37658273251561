<template>
    <div style="text-align: center;margin: 5% 0;">
      <el-result icon="success" title="批量导入完成" :subTitle="successDataMore !== undefined ? `本次成功导入${successDataMore}条数据` : ''">
        <template slot="extra">
          <xk-button type="primary" size="medium" style="margin: 40px 0;width: 150px" @click="close">完成</xk-button>
        </template>
      </el-result>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      successDataMore: {
        type: Number
      }
    },
    methods: {
      close() {
        this.$eventDispatch('closeMore')
      }
    }
  }
  </script>
  
  <style scoped>
  /deep/ .el-result .icon-success {
    fill: #36d9b0
  }
  /deep/.el-result__icon svg {
    width: 120px;
    height: 120px;
  }
  /deep/ .el-result__title > p {
    color: #36d9b0;
    font-weight: bold;
  }
  </style>
  