<template>
    <div>
      <div class="second-title-wrapper">
        <div class="second-title">导入执行</div>
        <div class="line"></div>
      </div>
      <import-execute
        :show="show"
        :uploadExcelHeaderMore="uploadExcelHeaderMore"
        :systemExcelHeaderMore="systemExcelHeaderMore"
        :keyMapMore="keyMapMore"
      />
      <div class="second-title-wrapper">
        <div class="second-title">导入设置</div>
        <div class="line"></div>
      </div>
      <el-row>
        <el-col :span="6" class="second-label">导入过程中遇到错误</el-col>
        <el-col :span="18">
          <el-radio v-model="importMoreForm.errType" label="1">继续执行</el-radio>
          <el-radio v-model="importMoreForm.errType" label="2">终止导入</el-radio>
          <div class="second-content">
            <p>
              继续执行：遇到错误数据不中断，正确数据导入完成后统一导出错误信息，错误信息不导入;
            </p>
            <p>
              终止导入：数据必须全部无误才可导入成功，一旦遇到错误即终止，系统导出全部数据，标记错误数据，修改后重新导入
            </p>
          </div>
        </el-col>
      </el-row>
      <!-- <el-row>
        <el-col :span="6" class="second-label">导入过程中，遇到相同数据</el-col>
        <el-col :span="18">
          <el-radio v-model="importMoreForm.sameType" label="1">覆盖导入</el-radio>
          <el-radio v-model="importMoreForm.sameType" label="2">追加导入</el-radio>
          <div class="second-content">
            <p>
              {{ importMoreInfo.sameInfo }}
            </p>
            <p>追加导入：保留原数据，新导入的无效</p>
          </div>
        </el-col>
      </el-row> -->
      <div style="text-align: center">
        <xk-button type="primary" @click="save" style="margin: 0">下一步</xk-button>
        <xk-button type="info" @click="before">返回重新上传</xk-button>
      </div>
    </div>
  </template>

  <script>
    import {
        debounce,
    } from "common-local";
  import importExecute from "./sub/importExecute";
  export default {
    components: {
      importExecute,
    },
    props: {
      importMoreForm: {
        type: Object,
      },
      systemExcelHeaderMore: {
        type: Array,
      },
      uploadExcelHeaderMore: {
        type: Array,
      },
      keyMapMore: {
        type: Object,
      },
      importMoreInfo: {
        type: Object
      },
      show: {
        type: Boolean
      }
    },
    methods: {
      save:debounce(function(){
        this.$eventDispatch("saveMore");
      },200, true),
      before() {
        this.$eventDispatch("beforeMore");
      },
    },
  };
  </script>

  <style scoped lang="scss">
  .second-title-wrapper {
    padding: 20px 0;
    display: flex;
    .second-title {
      font-size: 15px;
      font-weight: bold;
      white-space: nowrap;
      color: #878787;
    }
    .line {
      width: 100%;
      margin-top: 10px;
      border-top: 1px dashed #CCCCCC
    }
  }

  .second-label {
    text-align: right;
    padding-right: 20px;
    color: #595959;
    font-size: 14px;
  }

  .second-content {
    font-size: 12px;
    color: #A6A6A6;
    padding: 20px 0;
  }
  </style>
